import { AxiosResponse } from 'axios';
import Request from '../../api/Request';
import { Commission, PartnerNodeAdvancedInfo, Payout, Rank, TreeNodes } from './networking.types';
import { SimplePaginateResponse, SubscriptionItemChangeRequest } from '../../app.types';
import { Order } from './networking.types';
import { OrgSubscription } from './networking.types';
import { Organization, UserData } from '../../utils';
import { FactUserSubscription } from '../admin/modules/subscriptions/subscriptions.types';
import { AdvancedOrganization } from './networking.types';

const getTree = ({
    id,
    filters = {},
}: {
    id: string;
    filters?: Record<any, any>;
}): Promise<AxiosResponse<TreeNodes>> => {
    return Request.get(`/organizations/${id}/networking/binary-tree`, {
        params: filters,
    });
};

const getEnrollerTree = ({
    id,
    filters = {},
}: {
    id: string;
    filters?: Record<any, any>;
}): Promise<AxiosResponse<TreeNodes>> => {
    return Request.get(`/organizations/${id}/networking/enroller-tree`, {
        params: filters,
    });
};

const getNodeAdvancedInfo = ({
    id,
    partnerId,
}: {
    id: string;
    partnerId: string;
}): Promise<AxiosResponse<PartnerNodeAdvancedInfo>> => {
    return Request.get(`/organizations/${id}/networking/node/${partnerId}`);
};

const getCommissions = ({
    id,
    filters,
}: {
    id: string;
    filters: Record<any, any>;
}): Promise<AxiosResponse<SimplePaginateResponse<Commission>>> => {
    return Request.get(`/organizations/${id}/networking/commissions`, {
        params: filters,
    });
};

const getPayout = ({
    id,
    payoutId,
}: {
    id: string;
    payoutId: string;
}): Promise<AxiosResponse<Payout>> => {
    return Request.get(`/organizations/${id}/networking/payouts/${payoutId}`);
};

const getPayouts = ({
    id,
    filters,
}: {
    id: string;
    filters: Record<any, any>;
}): Promise<AxiosResponse<SimplePaginateResponse<Payout>>> => {
    return Request.get(`/organizations/${id}/networking/payouts`, {
        params: filters,
    });
};

const getRanks = ({
    id,
    filters,
}: {
    id: string;
    filters: Record<any, any>;
}): Promise<AxiosResponse<Rank[]>> => {
    return Request.get(`/organizations/${id}/networking/ranks`, {
        params: filters,
    });
};

interface PaginatedApiResponse {
    data: Array<Order>;
    links: any;
    meta: any;
}

interface PaginatedApiBuyerOrgResponse {
    data: Array<Organization>;
    links: any;
    meta: any;
}

interface PaginatedApiBuyerUserResponse {
    data: Array<UserData>;
    links: any;
    meta: any;
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;
type ReturnTypeBuyerOrgCollection = Promise<AxiosResponse<PaginatedApiBuyerOrgResponse>>;
type ReturnTypeBuyerUserCollection = Promise<AxiosResponse<PaginatedApiBuyerUserResponse>>;

interface GetOrdersParams {
    order_status?: string;
    seller_org_uuid?: string;
    buyer_org_uuid?: string;
    athlete_uuid?: string;
    level?: number;
    sort?: 'created_at' | '-created_at';
    page?: number;
    per_page?: number;
}

const getOrders = (
    orgUuid: string,
    isSeller: boolean = true,
    params?: GetOrdersParams,
): ReturnTypeCollection => {
    const filterParams = {
        'filter[order_status]': params?.order_status,
        'filter[buyer_org_uuid]': params?.buyer_org_uuid,
        'filter[seller_org_uuid]': params?.seller_org_uuid,
        'filter[athlete_uuid]': params?.athlete_uuid,
        level: params?.level,
        is_seller: isSeller ? 1 : 0,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };

    return Request.get(`organizations/${orgUuid}/marketplace/orders`, { params: filterParams });
};

const getOrderOrganizations = (
    orgUuid: string,
    isSeller: boolean = true,
    name: string | null,
    level: number | null,
): ReturnTypeBuyerOrgCollection => {
    return Request.get(`organizations/${orgUuid}/marketplace/orders/organizations`, {
        params: { 'filter[name]': name, is_seller: isSeller ? 1 : 0, level: level },
    });
};

const getOrderAthletes = (
    orgUuid: string,
    isSeller: boolean = true,
    name: string | null,
): ReturnTypeBuyerUserCollection => {
    return Request.get(`organizations/${orgUuid}/marketplace/orders/athletes`, {
        params: { 'filter[name]': name, is_seller: isSeller ? 1 : 0 },
    });
};

type ReturnTypeOrderStatuses = Promise<AxiosResponse<Array<string>>>;

const getOrderStatuses = (orgUuid: string): ReturnTypeOrderStatuses => {
    return Request.get(`organizations/${orgUuid}/marketplace/order-statuses`);
};

type ReturnTypeCommissionTypes = Promise<AxiosResponse<Array<string>>>;

const getCommissionTypes = (orgUuid: string): ReturnTypeCommissionTypes => {
    return Request.get(`organizations/${orgUuid}/networking/commission-types`);
};

interface OrgSubscriptionPaginatedApiResponse {
    data: Array<OrgSubscription>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

interface UserSubscriptionPaginatedApiResponse {
    data: Array<FactUserSubscription>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

interface SubscriptionItemChangeRequestPaginatedApiResponse {
    data: Array<SubscriptionItemChangeRequest>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

interface OrgDashboardPaginatedApiResponse {
    data: Array<AdvancedOrganization>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type OrgReturnTypeCollection = Promise<AxiosResponse<OrgSubscriptionPaginatedApiResponse>>;
type UserReturnTypeCollection = Promise<AxiosResponse<UserSubscriptionPaginatedApiResponse>>;
type SubscriptionChangeRequestReturnTypeCollection = Promise<
    AxiosResponse<SubscriptionItemChangeRequestPaginatedApiResponse>
>;
type OrgDashboardReturnTypeCollection = Promise<AxiosResponse<OrgDashboardPaginatedApiResponse>>;

interface GetOrgSubscriptionParams {
    name?: string;
    organization_uuid?: string;
    stripe_status: string;
    level: string;
    sort?: 'name' | 'organization_name' | '-created_at';
    between_dates?: Array<Date | undefined>;
    page?: number;
    per_page?: number;
}

interface GetUserSubscriptionParams {
    name?: string;
    user_name?: string;
    stripe_status?: string;
    level: string;
    sort?:
        | 'fact_user_subscription.name'
        | 'fact_user_subscription.user_name'
        | '-fact_user_subscription.created_at';
    page?: number;
    per_page?: number;
}

const getOrgSubscriptions = (
    orgUuid: string,
    params?: GetOrgSubscriptionParams,
): OrgReturnTypeCollection => {
    console.log(params);
    const filterParams = {
        'filter[name]': params?.name,
        'filter[stripe_status]': params?.stripe_status,
        level: params?.level,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`organizations/${orgUuid}/networking/org-subscriptions`, {
        params: filterParams,
    });
};

const getUserSubscriptions = (
    orgUuid: string,
    params?: GetUserSubscriptionParams,
): UserReturnTypeCollection => {
    if (params?.name === 'all') {
        params.name = '';
    }
    const filterParams = {
        'filter[fact_user_subscription.name]': params?.name,
        'filter[user_name]': params?.user_name,
        'filter[fact_user_subscription.stripe_status]': params?.stripe_status,
        level: params?.level,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`organizations/${orgUuid}/networking/user-subscriptions`, {
        params: filterParams,
    });
};

const changeOrganizationSubscriptionItemTier = (
    priceId: string,
    orgUuid: string,
    subscriptionItemId: number,
): Promise<AxiosResponse> => {
    return Request.post(`/organizations/${orgUuid}/networking/org-subscription-item-change-tier`, {
        price_id: priceId,
        subscription_item_id: subscriptionItemId,
    });
};

const cancelOrganizationSubscriptionItem = (
    orgUuid: string,
    subscriptionItemId: number,
): Promise<AxiosResponse> => {
    return Request.post(`/organizations/${orgUuid}/networking/org-subscription-item-cancel`, {
        subscription_item_id: subscriptionItemId,
    });
};

const requestSubscriptionItemChange = (
    orgUuid: string,
    subscriptionItemId: number,
    changeRequestType: string,
    changeRequestReason: string,
): Promise<AxiosResponse> => {
    return Request.post(
        `/organizations/${orgUuid}/networking/org-subscription-item-change-request`,
        {
            subscription_item_id: subscriptionItemId,
            change_request_type: changeRequestType,
            change_request_reason: changeRequestReason,
        },
    );
};

const getPendingSubscriptionItemChangeRequests = (
    orgUuid: string,
): SubscriptionChangeRequestReturnTypeCollection => {
    return Request.get(`/organizations/${orgUuid}/networking/org-subscription-item-change-request`);
};

interface GetDashboardOrganizationParams {
    organization_uuid?: string;
    sort?:
        | 'created_at'
        | '-created_at'
        | 'name'
        | '-name'
        | 'athlete_count'
        | '-athlete_count'
        | 'athlete_pod_subscriptions'
        | '-athlete_pod_subscriptions'
        | 'uploads_this_month'
        | '-uploads_this_month'
        | 'subscription_level'
        | '-subscription_level'
        | 'total_commissions'
        | '-total_commissions'
        | 'marketplace_commissions'
        | '-marketplace_commissions'
        | 'pib_bonus_commissions'
        | '-pib_bonus_commissions'
        | 'residual_commissions'
        | '-residual_commissions'
        | 'check_match_commissions'
        | '-check_match_commissions'
        | 'org_pod_subscriptions'
        | '-org_pod_subscriptions';
    page?: number;
    per_page?: number;
}

const getOrgDashboardMetrics = (
    orgUuid: string,
    params: GetDashboardOrganizationParams,
): OrgDashboardReturnTypeCollection => {
    console.log(params);
    const filterParams = {
        'filter[organization_uuid]': params['organization_uuid'],
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`organizations/${orgUuid}/dashboard`, { params: filterParams });
};

export { getOrgSubscriptions, getUserSubscriptions };
export type { GetOrgSubscriptionParams, GetUserSubscriptionParams, GetDashboardOrganizationParams };
export { getOrders, getOrderStatuses };
export type { GetOrdersParams };

export {
    getTree,
    getCommissions,
    getCommissionTypes,
    getRanks,
    getEnrollerTree,
    getPayouts,
    getPayout,
    getOrderOrganizations,
    getNodeAdvancedInfo,
    getOrderAthletes,
    changeOrganizationSubscriptionItemTier,
    cancelOrganizationSubscriptionItem,
    requestSubscriptionItemChange,
    getPendingSubscriptionItemChangeRequests,
    getOrgDashboardMetrics,
};
