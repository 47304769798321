import { Avatar, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { useTheme } from '@mui/system';

interface Props {
    onCloseClicked: () => void;
    onResendClicked: () => void;
    visible: boolean;
    email: string;
    name: string;
}

const VerifyEmailBanner = ({ onCloseClicked, onResendClicked, visible, email, name }: Props) => {
    const theme = useTheme();
    return (
        <>
            {visible && (
                <>
                    <Paper elevation={0} sx={{ padding: `8px 8px 8px 8px` }}>
                        <Grid container wrap="nowrap" spacing={8} alignItems="center">
                            <Grid item>
                                <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                                    <EmailIcon />
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>{name}</span>, fully
                                    activate your account by clicking the link in the email
                                    confirmation we sent to{' '}
                                    <span style={{ fontWeight: 'bold' }}>{email}</span>.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end" spacing={8}>
                            <Grid item>
                                <Button color="primary" onClick={onCloseClicked}>
                                    Close
                                </Button>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={onResendClicked}
                                >
                                    Resend Email
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Divider />
                </>
            )}
        </>
    );
};

export default VerifyEmailBanner;
