import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    MenuItem,
    Paper,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import {
    DataGridPremium,
    GridSortModel,
    GridRenderCellParams,
    GridColDef,
    GridValueFormatter,
} from '@mui/x-data-grid-premium';
import { debounce } from 'lodash';
import { Individual, Organization } from 'utils';
import { adminGetAllOrganizations } from '../../../admin.organizations.api';
import {
    changePodIndividual,
    changePodOrganization,
    createPod,
    detachPodIndividual,
    getPodHistory,
    getPods,
    GetPodsParams,
    updatePod,
} from '../pods.api';
import { CreatePodParams, Pod } from '../pods.types';
import { adminGetIndividuals } from '../../../admin.individuals.api';
import CheckIcon from '@mui/icons-material/Check';
import SnackbarContent from '@mui/material/SnackbarContent';
import Snackbar from '@mui/material/Snackbar';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import { ClockIcon } from '@mui/x-date-pickers';
import { DataGrid } from '@mui/x-data-grid';

const ResponsiveForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: theme.spacing(-1),
}));

const ResponsiveFormItem = styled(Box)(({ theme }) => {
    const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
    return {
        width: isNarrow ? '100%' : '80%',
        margin: theme.spacing(1),
        marginBottom: '6px',
    };
});

const PodList = () => {
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
    const [pods, setPods] = useState<Pod[]>([]);
    const [individualsForPodCreate, setIndividualsForPodCreate] = useState<Individual[]>([]);
    const [individualsForPodSearch, setIndividualsForPodSearch] = useState<Individual[]>([]);
    const [selectedIndividualForPodCreate, setSelectedIndividualForPodCreate] =
        useState<Individual | null>(null);
    const [selectedIndividualForPodSearch, setSelectedIndividualForPodSearch] =
        useState<Individual | null>(null);
    const [podTransferNameFilterForCreate, setPodTransferNameFilterForCreate] =
        useState<string>('');
    const [podAthleteNameFilterForSearch, setPodAthleteNameFilterForSearch] = useState<string>('');

    const [searchParams, setSearchParams] = useState<GetPodsParams>({
        uuid: '',
        serial_number: '',
        club_id: '',
        simple_id: '',
        organization_uuid: '',
        athlete_user_uuid: '',
        created_at: '',
        sort: '-created_at',
    });

    // Form data for create new pod form.
    const [newPodData, setNewPodData] = useState({
        organization_uuid: '',
        serial_number: '',
        simple_id: '',
        club_id: '',
        individual_uuid: '',
        decrement_org_pod_subscription: false,
    });

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [totalRows, setTotalRows] = useState(0);
    const searchParamsRef = useRef(searchParams);
    const [editedPods, setEditedPods] = useState<{ [key: string]: boolean }>({});
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [editedFields, setEditedFields] = useState<{
        [key: string]: { simple_id?: string; club_id?: string };
    }>({});

    useEffect(() => {
        searchParamsRef.current = searchParams;
    }, [searchParams]);

    useEffect(() => {
        const params = {
            ...searchParamsRef.current,
            per_page: paginationModel.pageSize,
            page: paginationModel.page + 1,
        };
        getPods(params)
            .then((response) => {
                setPods(response.data.data);
                setTotalRows(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch pods', error);
            });
    }, [paginationModel]);

    const openDialogModal = (title: string, message: string) => {
        setDialogTitle(title);
        setDialogMessage(message);
        setOpenDialog(true);
    };

    const closeDialogModal = () => {
        setOpenDialog(false);
    };

    const fetchAllOrganizations = debounce((searchTerm: string, perPage?: number) => {
        // Replace this with your actual API call
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setAllOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    useEffect(() => {
        const fetchIndividualsForOrganization = (organizationUuid: string) => {
            adminGetIndividuals({
                'filter[organization]': organizationUuid,
                'filter[name]': podTransferNameFilterForCreate,
                'filter[has_managing_user]': 1,
            })
                .then((response) => {
                    setIndividualsForPodCreate(response.data.data);
                })
                .catch((error) => {
                    console.error('Failed to fetch individuals', error);
                });
        };

        const fetchIndividualsForPodSearch = () => {
            adminGetIndividuals({
                'filter[organization]': searchParams.organization_uuid,
                'filter[name]': podAthleteNameFilterForSearch,
                'filter[has_managing_user]': 1,
            })
                .then((response) => {
                    setIndividualsForPodSearch(response.data.data);
                })
                .catch((error) => {
                    console.error('Failed to fetch individuals', error);
                });
        };

        if (selectedOrganization) {
            fetchIndividualsForOrganization(selectedOrganization.uuid);
        } else {
            setIndividualsForPodCreate([]);
        }

        if (searchParams.organization_uuid || podAthleteNameFilterForSearch) {
            fetchIndividualsForPodSearch();
        } else {
            setIndividualsForPodSearch([]);
        }
    }, [
        selectedOrganization,
        podTransferNameFilterForCreate,
        podAthleteNameFilterForSearch,
        searchParams.organization_uuid,
    ]);

    const fetchOrganizations = debounce((searchTerm: string, perPage?: number) => {
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    useEffect(() => {
        fetchAllOrganizations('', 500);
        fetchOrganizations('', 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function to handle form submission
    const handleNewPodSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // Validate the organization UUID
        if (!newPodData.organization_uuid) {
            openDialogModal('Error', 'Please select an organization.');
            return;
        }

        // Validate the serial number
        const serialNumberRegex = /^[A-F0-9]{16}$/;
        if (!serialNumberRegex.test(newPodData.serial_number)) {
            openDialogModal(
                'Error',
                'Invalid serial number. It must be 16 hexadecimal characters long.',
            );
            return;
        }

        // Validate the organization UUID
        if (!newPodData.simple_id) {
            openDialogModal('Error', 'Please input a simple ID');
            return;
        }

        try {
            const podData: CreatePodParams = {
                organization_uuid: newPodData.organization_uuid,
                serial_number: newPodData.serial_number,
                simple_id: newPodData.simple_id,
                club_id: newPodData.club_id,
                individual_uuid: newPodData.individual_uuid || null,
                decrement_org_pod_subscription: newPodData.decrement_org_pod_subscription,
            };
            await createPod(podData);
            // Clear the form
            setNewPodData({
                organization_uuid: '',
                serial_number: '',
                simple_id: '',
                club_id: '',
                individual_uuid: '',
                decrement_org_pod_subscription: false,
            });
            // Fetch the updated list of pods
            const response = await getPods(searchParams);
            setPods(response.data.data);
            setTotalRows(response.data.meta.total);
            setSelectedIndividualForPodCreate(null);
            openDialogModal(
                'Success',
                `Pod with serial number ${podData.serial_number} created successfully.`,
            );
        } catch (error: any) {
            openDialogModal('Error', error.response.data.message);
            console.error(error);
        }
    };

    const handleFieldChange = useCallback(
        (value: string, row: Pod, field: 'simple_id' | 'club_id') => {
            setEditedFields((prev) => ({
                ...prev,
                [row.uuid]: { ...prev[row.uuid], [field]: value },
            }));
            setEditedPods((prev) => ({ ...prev, [row.uuid]: true }));
        },
        [],
    );

    const handleUpdate = useCallback(
        async (row: Pod) => {
            try {
                const editedData = editedFields[row.uuid] || {};
                const updateData = {
                    simple_id: editedData.simple_id ?? row.simple_id,
                    club_id: editedData.club_id ?? row.club_id,
                };

                await updatePod(row.uuid, updateData);

                setPods((prevPods) =>
                    prevPods.map((pod) =>
                        pod.uuid === row.uuid ? { ...pod, ...updateData } : pod,
                    ),
                );
                setEditedFields((prev) => {
                    const newState = { ...prev };
                    delete newState[row.uuid];
                    return newState;
                });
                setEditedPods((prev) => ({ ...prev, [row.uuid]: false }));
                setSnackbarMessage('Pod updated successfully!');
                setSnackbarOpen(true);
            } catch (error: any) {
                console.error(`Failed to update pod`, error);
                openDialogModal('Error', `Failed to update pod: ${error.response.data.message}`);
            }
        },
        [editedFields],
    );

    const columns: GridColDef[] = [
        {
            field: 'serial_number',
            headerName: 'Serial Number',
            width: 160,
            sortable: true,
        },
        {
            field: 'simple_id',
            headerName: 'Simple ID',
            width: 120,
            sortable: true,
            renderCell: (params: GridRenderCellParams<Pod>) => (
                <TextField
                    value={editedFields[params.row.uuid]?.simple_id ?? params.value}
                    onChange={(e) => handleFieldChange(e.target.value, params.row, 'simple_id')}
                    size="small"
                    fullWidth
                    sx={{ height: '80%' }}
                    InputProps={{
                        sx: { height: '100%' },
                    }}
                />
            ),
        },
        {
            field: 'club_id',
            headerName: 'Club ID',
            width: 75,
            sortable: true,
            renderCell: (params: GridRenderCellParams<Pod>) => (
                <TextField
                    value={editedFields[params.row.uuid]?.club_id ?? params.value}
                    onChange={(e) => handleFieldChange(e.target.value, params.row, 'club_id')}
                    size="small"
                    fullWidth
                    sx={{ height: '80%' }}
                    InputProps={{
                        sx: { height: '100%' },
                    }}
                />
            ),
        },
        {
            field: 'organization_name',
            headerName: 'Organization',
            width: 150,
            sortable: true,
        },
        { field: 'athlete_name', headerName: 'Athlete', width: 150, sortable: true },
        {
            field: 'transfer_into',
            headerName: 'Transfer Request Sent',
            width: 200,
            sortable: false,
            renderCell: (params: GridRenderCellParams<Pod>) => {
                const transferTo = params.row.transfer_request_sent_to;
                const transferAt = params.row.transfer_request_sent_at
                    ? new Date(params.row.transfer_request_sent_at).toLocaleDateString('en-CA')
                    : '';
                return transferTo ? (
                    <div>
                        <span style={{ fontSize: '0.7rem' }}>{transferTo}&nbsp;</span>
                        <Chip
                            label={transferAt}
                            color="primary"
                            sx={{
                                borderRadius: '2px',
                                fontSize: '0.6rem',
                                height: '16px',
                                '& span': {
                                    padding: '1px',
                                },
                            }}
                        />
                    </div>
                ) : (
                    ''
                );
            },
        },
        {
            field: 'transfer_request_accepted_at',
            headerName: 'Transfer Request Accepted',
            width: 220,
            sortable: true,
            valueFormatter: ((value: string) => {
                return value ? new Date(value).toLocaleDateString('en-CA') : '';
            }) as GridValueFormatter<Pod>,
        },
        {
            field: 'subscription_id',
            headerName: 'User Subscription',
            width: 140,
            renderCell: (params: GridRenderCellParams<Pod>) => {
                return params.row.subscription_id ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <a
                            href={`https://dashboard.stripe.com/subscriptions/${params.row.subscription_id}`}
                            target="_blank"
                            rel="noreferrer"
                            title="View Subscription"
                        >
                            <CircularCheckIcon />
                        </a>
                    </Box>
                ) : (
                    ''
                );
            },
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 140,
            sortable: true,
            valueFormatter: ((params: number) => {
                return params ? new Date(params).toLocaleDateString('en-CA') : '';
            }) as GridValueFormatter<Pod>,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 350,
            renderCell: (params: GridRenderCellParams<Pod>) => (
                <>
                    <Button
                        onClick={() => handleUpdate(params.row)}
                        disabled={!editedPods[params.row.uuid]}
                        variant="contained"
                        size="small"
                        sx={{ marginRight: '4px', fontSize: '0.6rem' }}
                    >
                        UPDATE
                    </Button>
                    <PodActionsMenu pod={params.row} />
                </>
            ),
        },
    ];

    const PodActionsMenu = ({ pod }: { pod: Pod }) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const [isChangingOrganization, setIsChangingOrganization] = useState<boolean>(false);
        const [selectedOrgForChange, setSelectedOrgForChange] = useState<Organization | null>(null);
        const [organizationsForOrgChangeModal, setOrganizationsForOrgChangeModal] = useState<
            Organization[]
        >([]);
        const [isChangingIndividual, setIsChangingIndividual] = useState<boolean>(false);
        const [individualsForChangeModal, setIndividualsForChangeModal] = useState<Individual[]>(
            [],
        );
        const [selectedIndividualForPodUpdate, setSelectedIndividualForPodUpdate] =
            useState<Individual | null>(null);
        const [isDetachingIndividual, setIsDetachingIndividual] = useState<boolean>(false);
        const [isViewingHistory, setIsViewingHistory] = useState<boolean>(false);
        const [podHistory, setPodHistory] = useState<any[]>([]);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        /**
         * Organization change option
         */
        const handleChangeOrganization = () => {
            handleClose();
            handleOpenOrgChangeDialog();
        };

        const fetchOrganizationsOrgChangeModal = debounce(
            (searchTerm: string, perPage?: number) => {
                adminGetAllOrganizations(searchTerm, perPage)
                    .then((response) => {
                        setOrganizationsForOrgChangeModal(response.data.data);
                    })
                    .catch((error) => {
                        console.error('Failed to fetch organizations', error);
                    });
            },
            300,
        );

        const handleOpenOrgChangeDialog = () => {
            fetchOrganizationsOrgChangeModal('', 100);
            setIsChangingOrganization(true);
        };

        const handleCloseOrgChangeDialog = () => setIsChangingOrganization(false);

        const handleOrgChangeOrgChangeDialog = async () => {
            if (selectedOrgForChange) {
                try {
                    await changePodOrganization(pod.uuid, selectedOrgForChange.uuid);
                    const params = {
                        ...searchParamsRef.current,
                        page: paginationModel.page + 1,
                        per_page: paginationModel.pageSize,
                    };
                    await getPodSearchResults(params);
                    handleCloseOrgChangeDialog();
                    setSnackbarMessage('Pod organization changed successfully!');
                    setSnackbarOpen(true);
                } catch (error: any) {
                    console.error('Failed to change pod organization', error);
                    openDialogModal(
                        'Error',
                        `Failed to change pod organization: ${error.response.data.message}`,
                    );
                }
            }
        };

        /**
         * Individual change option.
         */
        const handleChangeIndividual = () => {
            handleClose();
            handleOpenIndividualChangeDialog();
        };

        const fetchIndividualChangeModal = debounce((name: string) => {
            adminGetIndividuals({
                'filter[organization]': pod.organization_uuid,
                'filter[name]': name,
                'filter[has_managing_user]': 1,
            })
                .then((response) => {
                    setIndividualsForChangeModal(response.data.data);
                })
                .catch((error) => {
                    console.error('Failed to fetch athletes', error);
                });
        }, 300);

        const handleOpenIndividualChangeDialog = () => {
            fetchIndividualChangeModal('');
            setIsChangingIndividual(true);
        };

        const handleCloseIndividualChangeDialog = () => setIsChangingIndividual(false);

        const handleChangeIndividualChangeDialog = async () => {
            if (selectedIndividualForPodUpdate) {
                try {
                    await changePodIndividual(pod.uuid, selectedIndividualForPodUpdate.uuid);
                    const params = {
                        ...searchParamsRef.current,
                        page: paginationModel.page + 1,
                        per_page: paginationModel.pageSize,
                    };
                    await getPodSearchResults(params);
                    handleCloseIndividualChangeDialog();
                    setSnackbarMessage('Individual changed successfully!');
                    setSnackbarOpen(true);
                } catch (error: any) {
                    console.error('Failed to change individual for pod', error);
                    openDialogModal(
                        'Error',
                        `Failed to change pod individual: ${error.response.data.message}`,
                    );
                }
            }
        };

        /**
         * Detach individual option.
         */
        const handleDetachIndividual = () => {
            handleClose();
            setIsDetachingIndividual(true);
        };

        const handleDetachIndividualDialog = async () => {
            try {
                await detachPodIndividual(pod.uuid);
                const params = {
                    ...searchParamsRef.current,
                    page: paginationModel.page + 1,
                    per_page: paginationModel.pageSize,
                };
                await getPodSearchResults(params);
                setIsDetachingIndividual(false);
                setSnackbarMessage('Individual detached from pod!');
                setSnackbarOpen(true);
            } catch (error: any) {
                console.error('Failed to detach individual for pod', error);
                openDialogModal(
                    'Error',
                    `Failed to detach pod individual: ${error.response.data.message}`,
                );
            }
        };

        /**
         * View history option.
         */
        const fetchPodHistory = () => {
            getPodHistory(pod.uuid)
                .then((response: any) => {
                    setPodHistory(response.data);
                })
                .catch((error: any) => {
                    console.error('Failed to fetch pod history', error);
                });
        };

        const handleViewHistory = () => {
            fetchPodHistory();
            setIsViewingHistory(true);
        };

        const podHistoryColumns: GridColDef[] = [
            { field: 'event', headerName: 'Event', flex: 1 },
            {
                field: 'organization',
                headerName: 'Organization',
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    const { organization_name, new_organization_name } = params.row;
                    if (organization_name && new_organization_name) {
                        return `${organization_name} -> ${new_organization_name}`;
                    }
                    return organization_name || '';
                },
            },
            { field: 'individual_name', headerName: 'Individual', flex: 1 },
            { field: 'user_name', headerName: 'User', flex: 1 },
            {
                field: 'event_properties',
                headerName: 'Event Properties',
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                    <Tooltip title={<Typography>{JSON.stringify(params.value)}</Typography>} arrow>
                        <Typography
                            noWrap
                            style={{
                                cursor: 'pointer',
                                fontSize: '0.9rem',
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            {JSON.stringify(params.value)}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                field: 'meta_data',
                headerName: 'Meta Data',
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                    <Tooltip title={<Typography>{JSON.stringify(params.value)}</Typography>} arrow>
                        <Typography
                            noWrap
                            style={{
                                cursor: 'pointer',
                                fontSize: '0.9rem',
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            {JSON.stringify(params.value)}
                        </Typography>
                    </Tooltip>
                ),
            },
            { field: 'created_at', headerName: 'Created At', flex: 1 },
        ];

        return (
            <>
                <Button
                    aria-controls={open ? 'pod-actions-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </Button>
                <Menu id="pod-actions-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem onClick={handleViewHistory} style={{ fontSize: '0.8rem' }}>
                        <ClockIcon fontSize="small" style={{ marginRight: 8 }} />
                        View History
                    </MenuItem>
                    <MenuItem onClick={handleChangeOrganization} style={{ fontSize: '0.8rem' }}>
                        <BusinessIcon fontSize="small" style={{ marginRight: 8 }} />
                        Change Organization
                    </MenuItem>
                    <MenuItem onClick={handleChangeIndividual} style={{ fontSize: '0.8rem' }}>
                        <PersonIcon fontSize="small" style={{ marginRight: 8 }} />
                        Change Individual
                    </MenuItem>
                    {pod.athlete_name && (
                        <MenuItem onClick={handleDetachIndividual} style={{ fontSize: '0.8rem' }}>
                            <PersonIcon fontSize="small" style={{ marginRight: 8 }} />
                            Detach Individual
                        </MenuItem>
                    )}
                </Menu>
                <Dialog
                    open={isChangingOrganization}
                    onClose={handleCloseOrgChangeDialog}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Change Organization</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            options={organizationsForOrgChangeModal}
                            getOptionLabel={(option) =>
                                `${option.name} (${option.subscription_tier?.name}) - ${option.uuid}`
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Select Organization" />
                            )}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchOrganizationsOrgChangeModal(newInputValue);
                                }
                            }}
                            onChange={(_, newValue) => setSelectedOrgForChange(newValue)}
                            value={selectedOrgForChange}
                            size="small"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseOrgChangeDialog} variant="contained">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleOrgChangeOrgChangeDialog}
                            disabled={!selectedOrgForChange}
                            variant="contained"
                        >
                            Change
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isChangingIndividual}
                    onClose={handleCloseIndividualChangeDialog}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Change Athlete</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            options={individualsForChangeModal}
                            getOptionLabel={(option) => `${option.name}`}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Individual" />
                            )}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchIndividualChangeModal(newInputValue);
                                }
                            }}
                            onChange={(_, newValue) => setSelectedIndividualForPodUpdate(newValue)}
                            value={selectedIndividualForPodUpdate}
                            size={'small'}
                            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                            renderOption={(props, option) => (
                                <li
                                    {...props}
                                    key={option.uuid}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    {option.image_urls.avatar ? (
                                        <img
                                            src={option.image_urls.avatar}
                                            alt={option.name}
                                            style={{
                                                marginRight: 8,
                                                width: 24,
                                                height: 24,
                                            }}
                                        />
                                    ) : (
                                        // Optional: Display a default image or leave as is for no image
                                        <div
                                            style={{
                                                marginRight: 8,
                                                width: 24,
                                                height: 24,
                                            }}
                                        ></div>
                                    )}
                                    {option.name} ({option.managing_user?.user_name})
                                </li>
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseIndividualChangeDialog} variant="contained">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleChangeIndividualChangeDialog}
                            disabled={!selectedIndividualForPodUpdate}
                            variant="contained"
                        >
                            Change
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isDetachingIndividual}
                    onClose={() => setIsDetachingIndividual(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to detach this individual from this pod?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDetachingIndividual(false)} variant="contained">
                            Cancel
                        </Button>
                        <Button onClick={handleDetachIndividualDialog} variant="contained">
                            Detach
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isViewingHistory}
                    onClose={() => setIsViewingHistory(false)}
                    maxWidth="xl"
                    fullWidth
                >
                    <DialogTitle>Pod History</DialogTitle>
                    <DialogContent>
                        <DataGrid
                            rows={podHistory}
                            columns={podHistoryColumns}
                            getRowId={(row) => row.aggregate_version}
                            autoHeight
                            disableColumnMenu
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsViewingHistory(false)} variant="contained">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    const handleRowUpdate = async (newRow: Pod, oldRow: Pod) => {
        if (newRow.simple_id !== oldRow.simple_id || newRow.club_id !== oldRow.club_id) {
            try {
                const updatedFields = {
                    simple_id: newRow.simple_id,
                    club_id: newRow.club_id,
                };
                await updatePod(newRow.uuid, updatedFields);
                return newRow;
            } catch (error) {
                console.error(`Failed to update pod`, error);
                openDialogModal('Error', `Failed to update pod`);
                return oldRow;
            }
        }
        return newRow;
    };

    const handleSearchParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setSearchParams({
            ...searchParams,
            [event.target.name as string]: event.target.value,
        });
    };

    const handlePodSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const params = {
                ...searchParams,
                organization_uuid: searchParams.organization_uuid || undefined,
                page: paginationModel.page + 1,
                per_page: paginationModel.pageSize,
            };
            await getPodSearchResults(params);
        } catch (error) {
            console.error(error);
        }
    };

    const getPodSearchResults = async (params: any) => {
        const paginatedParams = {
            ...params,
            page: paginationModel.page + 1, // +1 because DataGrid uses 0-based indexing
            per_page: paginationModel.pageSize,
        };
        const response = await getPods(paginatedParams);
        console.debug('Pods response', response.data.data);
        if (Array.isArray(response.data.data)) {
            const updatedPods = response.data.data.map((pod: Pod) => {
                const transferRequestAcceptedAt = pod.transfer_request_accepted_at
                    ? new Date(pod.transfer_request_accepted_at).toLocaleString('en-US', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                      })
                    : '';
                return {
                    ...pod,
                    created_at: new Date(pod.created_at),
                    transfer_request_accepted_at: transferRequestAcceptedAt,
                };
            });
            setPods(updatedPods);
            setTotalRows(response.data.meta.total);
        } else {
            console.error('Unexpected data format: pods data is not an array');
        }
    };

    const handlePodSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        /**
         * Sort will be undefined, or it will be ascending, ie "createdAt", or in this case
         * it will be descending, ie "-createdAt". If descending, we prepend a hyphen.
         */
        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        // Update your API request with the new sort model and fetch the data again
        const params = {
            ...searchParams,
            sort: sortField as GetPodsParams['sort'],
            page: paginationModel.page + 1,
            per_page: paginationModel.pageSize,
        };

        await getPodSearchResults(params);
    };

    const handleNewPodParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = event.target;
        let updatedSimpleId = newPodData.simple_id;

        if (name === 'serial_number' || name === 'club_id') {
            const serialNumber = name === 'serial_number' ? value : newPodData.serial_number;
            const clubId = name === 'club_id' ? value : newPodData.club_id;
            if (serialNumber && clubId) {
                updatedSimpleId = `${serialNumber.slice(-4)}-${clubId}`;
            } else if (serialNumber) {
                updatedSimpleId = `${serialNumber.slice(-4)}-`;
            } else if (clubId) {
                updatedSimpleId = `-${clubId}`;
            } else {
                updatedSimpleId = '';
            }
        }

        setNewPodData({
            ...newPodData,
            [name]: value,
            simple_id: name === 'simple_id' ? value : updatedSimpleId,
        });
    };

    const debouncedSetPodTransferNameFilter = debounce((newInputValue) => {
        setPodTransferNameFilterForCreate(newInputValue);
    }, 300);

    const debouncedSetPodAthleteNameSearchFilter = debounce((newInputValue) => {
        setPodAthleteNameFilterForSearch(newInputValue);
    }, 300);

    const CircularCheckIcon = () => (
        <Box
            sx={{
                width: 24,
                height: 24,
                borderRadius: '50%',
                backgroundColor: 'green',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CheckIcon style={{ color: 'white', fontSize: 16 }} />
        </Box>
    );

    // @ts-ignore
    return (
        <Box sx={{ margin: theme.spacing(0, 2) }}>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <SnackbarContent message={snackbarMessage} />
            </Snackbar>
            <h2>Pods</h2>
            <section style={{ marginBottom: '2rem' }}>
                <h4>Create New Pod</h4>
                <ResponsiveForm onSubmit={handleNewPodSubmit} noValidate>
                    <ResponsiveFormItem>
                        <Autocomplete
                            value={
                                allOrganizations.find(
                                    (org) => org.uuid === newPodData.organization_uuid,
                                ) || null
                            }
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchAllOrganizations(newInputValue);
                                }
                            }}
                            onChange={(event, newValue) => {
                                setSelectedOrganization(newValue);
                                setSelectedIndividualForPodCreate(null);
                                setNewPodData({
                                    ...newPodData,
                                    organization_uuid: newValue ? newValue.uuid : '',
                                });
                            }}
                            getOptionKey={(option) => option.uuid}
                            filterOptions={(options) => options}
                            size={'small'}
                            fullWidth
                            options={allOrganizations}
                            getOptionLabel={(option) =>
                                `${option.name} (${option.subscription_tier?.name})`
                            }
                            renderOption={(props, option) => (
                                <li {...props}>
                                    {`${option.name} (${option.subscription_tier?.name}) - ${option.uuid}`}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Organization" />
                            )}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Autocomplete
                            id="individuals-select"
                            options={individualsForPodCreate}
                            getOptionLabel={(option) => option.name}
                            value={selectedIndividualForPodCreate}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason === 'input') {
                                    debouncedSetPodTransferNameFilter(newInputValue);
                                }
                            }}
                            onChange={(event, newValue) => {
                                setSelectedIndividualForPodCreate(newValue);
                                setNewPodData({
                                    ...newPodData,
                                    individual_uuid: newValue ? newValue.uuid : '',
                                });
                            }}
                            fullWidth
                            size={'small'}
                            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                            renderOption={(props, option) => (
                                <li
                                    {...props}
                                    key={option.uuid}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    {option.image_urls.avatar ? (
                                        <img
                                            src={option.image_urls.avatar}
                                            alt={option.name}
                                            style={{
                                                marginRight: 4,
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                marginRight: 4,
                                                width: 20,
                                                height: 20,
                                            }}
                                        ></div>
                                    )}
                                    <span style={{ fontSize: '0.7rem' }}>
                                        {option.name}
                                        &nbsp; ({option.managing_user?.first_name}{' '}
                                        {option.managing_user?.last_name} -{' '}
                                        {option.managing_user?.user_name})
                                    </span>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Individual" />
                            )}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            name="serial_number"
                            value={newPodData.serial_number}
                            onChange={handleNewPodParameterChange}
                            placeholder="Enter Serial Number"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            name="club_id"
                            value={newPodData.club_id}
                            onChange={handleNewPodParameterChange}
                            placeholder="Enter Club ID"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            name="simple_id"
                            value={newPodData.simple_id}
                            onChange={handleNewPodParameterChange}
                            placeholder="Enter Simple ID"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="decrement_org_pod_subscription"
                                    checked={newPodData.decrement_org_pod_subscription || false}
                                    onChange={(event) =>
                                        setNewPodData({
                                            ...newPodData,
                                            decrement_org_pod_subscription: event.target.checked,
                                        })
                                    }
                                    disabled={!selectedIndividualForPodCreate}
                                    style={{ marginLeft: '8px' }}
                                />
                            }
                            label="Decrement Org Pod Subscription"
                            sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.9rem' } }}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            size={'medium'}
                            variant="contained"
                            fullWidth={isNarrow}
                        >
                            Create Pod
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h4>Filter Pods</h4>
                <ResponsiveForm onSubmit={handlePodSearch} noValidate>
                    <ResponsiveFormItem>
                        <Autocomplete
                            value={
                                organizations.find(
                                    (org) => org.uuid === searchParams.organization_uuid,
                                ) || null
                            }
                            onChange={(event, newValue) => {
                                setSelectedIndividualForPodSearch(null);
                                setSearchParams({
                                    ...searchParams,
                                    organization_uuid: newValue ? newValue.uuid : '',
                                });
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchOrganizations(newInputValue);
                                }
                            }}
                            getOptionKey={(option) => option.uuid}
                            filterOptions={(options) => options}
                            size={'small'}
                            fullWidth
                            options={organizations}
                            getOptionLabel={(option) =>
                                `${option.name} (${option.subscription_tier?.name})`
                            }
                            renderOption={(props, option) => (
                                <li {...props}>
                                    {`${option.name} (${option.subscription_tier?.name}) - ${option.uuid}`}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="All Organizations" />
                            )}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Autocomplete
                            id="individuals-select-search"
                            options={individualsForPodSearch}
                            getOptionLabel={(option) => option.name}
                            value={selectedIndividualForPodSearch}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason === 'input') {
                                    debouncedSetPodAthleteNameSearchFilter(newInputValue);
                                }
                            }}
                            onChange={(event, newValue) => {
                                setSelectedIndividualForPodSearch(newValue);
                                setSearchParams({
                                    ...searchParams,
                                    athlete_user_uuid: newValue
                                        ? newValue.managing_user_uuid ?? ''
                                        : '',
                                });
                            }}
                            fullWidth
                            size={'small'}
                            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                            renderOption={(props, option) => (
                                <li
                                    {...props}
                                    key={option.uuid}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    {option.image_urls.avatar ? (
                                        <img
                                            src={option.image_urls.avatar}
                                            alt={option.name}
                                            style={{
                                                marginRight: 4,
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                marginRight: 4,
                                                width: 20,
                                                height: 20,
                                            }}
                                        ></div>
                                    )}
                                    <span style={{ fontSize: '0.7rem' }}>
                                        <strong>{option.name}</strong>
                                        &nbsp; ({option.managing_user?.first_name}{' '}
                                        {option.managing_user?.last_name} -{' '}
                                        {option.managing_user?.user_name}) -{' '}
                                        {option.organization.name}
                                    </span>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Individual" />
                            )}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            name="serial_number"
                            value={searchParams.serial_number}
                            onChange={handleSearchParameterChange}
                            placeholder="Enter Serial Number"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            name="club_id"
                            value={searchParams.club_id}
                            onChange={handleSearchParameterChange}
                            placeholder="Enter Club ID"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            name="simple_id"
                            value={searchParams.simple_id}
                            onChange={handleSearchParameterChange}
                            placeholder="Enter Simple ID"
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            size={'medium'}
                            variant="contained"
                            fullWidth={isNarrow}
                        >
                            Search Pods
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>
            <Box width="98%" height="auto" overflow="auto" className="dataGridContainer">
                <DataGridPremium<Pod>
                    rows={pods}
                    columns={columns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model) => handlePodSort(model)}
                    getRowId={(row) => row.uuid}
                    style={{ maxHeight: '1200px', minHeight: '200px', marginBottom: '50px' }}
                    paginationMode="server"
                    pagination
                    rowCount={totalRows}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    processRowUpdate={handleRowUpdate}
                    disableColumnMenu={true}
                    sx={{
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                    }}
                />
            </Box>
            <Dialog
                open={openDialog}
                onClose={closeDialogModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Paper style={{ minWidth: '300px' }}>
                    <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialogModal} variant="contained" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        </Box>
    );
};

export default PodList;
