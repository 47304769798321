import { Box, useMediaQuery, useTheme } from '@mui/material';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isSubscribedToAthleteSubscription } from '../app.functions';
import { VerifyEmailBanner } from '../components';
import AthleteSubscriptionBanner from '../components/Cards/AthleteSubscriptionBanner';
import { AthleteContext } from '../contexts/AthleteContext';
import { NotificationDataProvider } from '../contexts/NotificationDataProviderContext';
import { authSelector } from '../redux/reducers/auth';
import {
    getOrganizationsForMovementCreation,
    resendEmailVerification,
    userSelector,
} from '../redux/reducers/user';
import { useAppDispatch } from '../redux/store';
import LayoutBox from './LayoutBox';
import DesktopTopNavigation from './Navigation/DesktopTopNavigation';
import MobileBottomNavigation from './Navigation/MobileBottomNavigation';
import MobileTopNavigation from './Navigation/MobileTopNavigation';

const NavigationLayout: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('md'));
    const { subscriptions, subscriptionsLoaded } = useContext(AthleteContext);
    const { organizations, loaded } = useContext(OrganizationsContext);
    const organizationsICanAddMovementsTo = useSelector(getOrganizationsForMovementCreation);
    const { userData } = useSelector(userSelector);
    const { isAuth } = useSelector(authSelector);
    const [bannerVisible, setBannerVisible] = useState(true);
    const dispatch = useAppDispatch();
    const [athleteSubscriptionsBannerVisible, setAthleteSubscriptionsBannerVisible] =
        useState(false);
    const [athleteSubscriptionBannerClosed, setAthleteSubscriptionBannerClosed] = useState(false);

    useEffect(() => {
        if (!subscriptionsLoaded) return;
        if (!loaded) return;

        const teamIdsUserIsAdminOrCoachFor = organizations
            .filter((org) => org.type !== 'personal')
            .map((org) => org.uuid);

        const isAdminOrCoach = organizationsICanAddMovementsTo?.some(
            (team) => teamIdsUserIsAdminOrCoachFor.findIndex((id) => id === team.name) !== -1,
        );

        const shouldSeeBanner = !isAdminOrCoach && userData && !organizationsICanAddMovementsTo;

        if (shouldSeeBanner && !isSubscribedToAthleteSubscription(subscriptions)) {
            setAthleteSubscriptionsBannerVisible(true);
        } else {
            setAthleteSubscriptionsBannerVisible(false);
        }
    }, [
        loaded,
        subscriptions,
        organizations,
        userData,
        subscriptionsLoaded,
        organizationsICanAddMovementsTo,
    ]);
    const resendVerificationEmail = () => {
        setBannerVisible(false);
        dispatch(resendEmailVerification({}));
    };

    return (
        <LayoutBox>
            {isAuth && (
                <NotificationDataProvider>
                    {isMobileDevice ? <MobileTopNavigation /> : <DesktopTopNavigation />}
                </NotificationDataProvider>
            )}
            <VerifyEmailBanner
                onCloseClicked={() => setBannerVisible(false)}
                onResendClicked={() => resendVerificationEmail()}
                visible={!!userData && !userData?.email_verified_at && bannerVisible}
                email={userData?.email ?? ''}
                name={userData?.first_name ?? ''}
            />
            <AthleteSubscriptionBanner
                onCloseClicked={() => setAthleteSubscriptionBannerClosed(true)}
                visible={athleteSubscriptionsBannerVisible && !athleteSubscriptionBannerClosed}
                name={userData?.first_name ?? ''}
            />

            <Box sx={{ flex: 1, overflowY: 'scroll' }}>{children}</Box>
            {isMobileDevice && <MobileBottomNavigation />}
        </LayoutBox>
    );
};

export default NavigationLayout;
