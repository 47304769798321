import {
    Box,
    CircularProgress,
    Container,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { NextPrevPagination } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Group, Member, Meta, PaginationLink } from 'utils';
import { getMembers, viewGroup } from '../api/groups.api';
import SpeedManagementIndividualCard from '../components/SpeedManagementIndividualCard';
import debounce from 'lodash/debounce';

const SpeedManagement: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [group, setGroup] = useState<Group>();
    const [members, setMembers] = useState<Member[]>();
    const [membersPaginationLinks, setMembersPaginationLinks] = useState<PaginationLink>();
    const [membersPaginationMeta, setMembersPaginationMeta] = useState<Meta>();
    const [error, setError] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState('');
    const { id } = useParams<{ id: string }>();

    const loadMembers = (uuid: string, page?: number, search?: string) => {
        setLoading(true);
        getMembers(uuid, {
            include: 'individual.managingUser,memberType',
            limit: 3,
            page,
            'filter[name]': search,
        })
            .then((response) => {
                setMembers(response.data.data);
                setMembersPaginationLinks(response.data.links);
                setMembersPaginationMeta(response.data.meta);
            })
            .catch(() => setError('Failed to load members'))
            .finally(() => setLoading(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((uuid: string, search: string) => {
            console.log('debounced search', search);
            loadMembers(uuid, 1, search);
        }, 500),
        [],
    );

    useEffect(() => {
        let isMounted = true;
        viewGroup(id)
            .then((groupResponse) => {
                if (isMounted) {
                    setGroup(groupResponse.data);
                }
            })
            .catch(() => {
                if (isMounted) {
                    setError('Failed to load group');
                }
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [id]);

    useEffect(() => {
        if (group && searchQuery !== undefined) {
            debouncedSearch(group.uuid, searchQuery);
        }
    }, [searchQuery, group, debouncedSearch]);

    const onNextClicked = () => {
        if (!group) return;
        const nextPage = membersPaginationMeta ? membersPaginationMeta.current_page + 1 : 1;
        loadMembers(group.uuid, nextPage, searchQuery);
    };

    const onPrevClicked = () => {
        if (!group) return;
        const prevPage = membersPaginationMeta ? membersPaginationMeta.current_page - 1 : 1;
        loadMembers(group.uuid, prevPage, searchQuery);
    };

    if (error) {
        return (
            <Container>
                <Typography color="error">{error}</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl">
            <Typography variant="h3" textAlign={'center'}>
                Speed Management
            </Typography>
            {group && (
                <Stack direction="row" spacing={2} alignItems="center" justifyContent={'center'}>
                    <img
                        src={group.image_urls?.avatar || '/default-avatar.png'}
                        alt={`${group.name} avatar`}
                        style={{ maxWidth: '40px', maxHeight: '40px', objectFit: 'cover' }}
                    />
                    <Typography variant="h2" sx={{ mb: 4 }}>
                        {group.name}
                    </Typography>
                </Stack>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                <TextField
                    sx={{ maxWidth: '400px', minWidth: '300px' }}
                    label="Search athletes"
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </Box>

            {loading ? (
                <Box display="flex" justifyContent="center" my={4}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Grid container spacing={3}>
                        {members?.map((member) => (
                            <Grid item xs={12} md={4} key={member.individual.uuid}>
                                <SpeedManagementIndividualCard individual={member.individual} />
                            </Grid>
                        ))}
                    </Grid>

                    {members && members.length > 0 && (
                        <Box sx={{ mt: 4 }}>
                            <NextPrevPagination
                                onNext={onNextClicked}
                                onPrev={onPrevClicked}
                                disablePrev={Boolean(!membersPaginationLinks?.prev)}
                                disableNext={Boolean(!membersPaginationLinks?.next)}
                            />
                        </Box>
                    )}
                </>
            )}
        </Container>
    );
};

export default SpeedManagement;
