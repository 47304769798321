import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { DataGridPremium, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { AdvancedOrganization } from '../networking.types';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useNetworkingOrganization } from '../state/NetworkingContext';
import { GetDashboardOrganizationParams, getOrgDashboardMetrics } from '../networking.api';

const DashboardMetricsScene = () => {
    const theme = useTheme();
    const [organizations, setOrganizations] = useState<AdvancedOrganization[]>([]);
    const [searchParams, setSearchParams] = useState<GetDashboardOrganizationParams>({
        sort: '-created_at',
        page: 1,
        per_page: 10,
    });
    const organization = useNetworkingOrganization();

    const [totalOrganizations, setTotalOrganizations] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        setSearchParams({
            ...searchParams,
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        });
    };

    useEffect(() => {
        getOrgDashboardMetrics(organization?.data?.uuid as string, {
            ...searchParams,
        })
            .then((response) => {
                setOrganizations(response.data.data);
                setTotalOrganizations(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, [organization, searchParams]);

    const formatCentsToDollars = (cents: number): string => {
        const dollars = cents / 100;
        return `$${dollars.toFixed(2)}`;
    };

    const columns: GridColDef<AdvancedOrganization, any, any>[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            sortable: true,
            type: 'string',
        },
        {
            field: 'sponsor_name',
            headerName: 'Sponsor',
            width: 150,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                return params.row.partner.sponsor?.organization.name;
            },
        },
        {
            field: 'subscription_level',
            headerName: 'Subscription Level',
            width: 120,
            sortable: true,
            type: 'string',
            renderCell: (params) => {
                return params.row.subscription_tier?.name;
            },
        },
        {
            field: 'uploads_this_month',
            headerName: 'Uploads this Month',
            width: 120,
            sortable: true,
            type: 'number',
        },
        {
            field: 'amount_past_due',
            headerName: 'Amount due',
            width: 120,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.amount_past_due || 0),
        },
        {
            field: 'total_commissions',
            headerName: 'Total Commissions',
            width: 150,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.total_commissions || 0),
        },
        {
            field: 'marketplace_commissions',
            headerName: 'Marketplace Commissions',
            width: 140,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.marketplace_commissions || 0),
        },
        {
            field: 'pib_bonus_commissions',
            headerName: 'PIB Bonus Commissions',
            width: 140,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.pib_bonus_commissions || 0),
        },
        {
            field: 'residual_commissions',
            headerName: 'Residual Commissions',
            width: 140,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.residual_commissions || 0),
        },
        {
            field: 'check_match_commissions',
            headerName: 'Check Match Commissions',
            width: 160,
            sortable: true,
            type: 'number',
            renderCell: (params) => formatCentsToDollars(params.row.check_match_commissions || 0),
        },
        {
            field: 'org_pod_subscriptions',
            headerName: 'Org Pod Subscriptions',
            width: 140,
            sortable: true,
            type: 'number',
        },
        {
            field: 'athlete_pod_subscriptions',
            headerName: 'Athlete Pod Subscriptions',
            width: 140,
            sortable: true,
            type: 'number',
        },
        {
            field: 'athlete_count',
            headerName: 'Athlete Count',
            width: 120,
            sortable: true,
            type: 'number',
        },
        {
            field: 'created_at',
            headerName: 'Joined',
            width: 100,
            sortable: true,
            type: 'string',
            renderCell: (params) => {
                const date = new Date(params.value);
                return date.toISOString().split('T')[0];
            },
        },
    ];

    const handleOrgSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        /**
         * Sort will be undefined, or it will be ascending, ie "createdAt", or in this case
         * it will be descending, ie "-createdAt". If descending, we prepend a hyphen.
         */
        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        // Update your API request with the new sort model and fetch the data again
        const params = {
            ...searchParams,
            sort: sortField as GetDashboardOrganizationParams['sort'],
        };

        try {
            const response = await getOrgDashboardMetrics(
                organization?.data?.uuid as string,
                params,
            );
            setOrganizations(response.data.data);
            setTotalOrganizations(response.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch sorted organizations', error);
        }
    };

    // @ts-ignore
    return (
        <Box sx={{ margin: theme.spacing(0, 2) }}>
            <h2>Organization Metrics</h2>
            <h4>Your organization and all level 1 tier partners</h4>
            <Box width="98%" maxHeight="600px" overflow="auto">
                <DataGridPremium
                    rows={organizations}
                    columns={columns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model: GridSortModel) => handleOrgSort(model)}
                    getRowId={(row) => row.uuid}
                    style={{ height: '624px', marginBottom: '50px' }}
                    pagination
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    rowCount={totalOrganizations}
                    paginationMode="server"
                    disableColumnMenu={true}
                    sx={{
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            fontSize: '0.6rem',
                        },
                        '& .MuiDataGrid-columnHeader': {
                            fontSize: '0.6rem',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontSize: '0.6rem',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            fontSize: '0.6rem',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default DashboardMetricsScene;
