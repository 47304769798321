import { Box, Drawer, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import React, { useCallback } from 'react';
import BinaryTreeScene from './BinaryTreeScene';
import PartnershipEarningsScene from './PartnershipEarningsScene';
import PartnershipDashboardScene from '../components/PartnershipDashboardScene';
import PartnershipRanksScene from './PartnershipRanksScene';
import { useNetworkingOrganization } from '../state/NetworkingContext';
import EnrollerTreeScene from './EnrollerTreeScene';
import PartnershipPayoutsScene from './PartnershipPayoutsScene';
import PartnershipPayoutCommissionsListScene from './PartnershipPayoutCommissionsListScene';
import ProductSalesListScene from './ProductSalesListScene';
import ProductOrdersListScene from './ProductOrdersListScene';
import SubscriptionScene from './SubscriptionScene';
import DashboardMetricsScene from './DashboardMetricsScene';

const OrganizationPartnerDetailsOverviewScene = () => {
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams<{ id: string }>();
    const { data } = useNetworkingOrganization();
    const handleChange = (event: any, newValue: string) => {
        history.push(newValue);
    };

    const getActiveTab = useCallback(() => {
        // Example logic for determining the active tab
        if (location.pathname.startsWith(`/networking/${id}/metrics`))
            return `/networking/${id}/metrics`;
        if (location.pathname.startsWith(`/networking/${id}/binary-tree`))
            return `/networking/${id}/binary-tree`;
        if (location.pathname.startsWith(`/networking/${id}/enroller-tree`))
            return `/networking/${id}/enroller-tree`;
        if (location.pathname.startsWith(`/networking/${id}/earnings`))
            return `/networking/${id}/earnings`;
        if (location.pathname.startsWith(`/networking/${id}/payouts`))
            return `/networking/${id}/payouts`;
        if (location.pathname.startsWith(`/networking/${id}/sales`))
            return `/networking/${id}/sales`;
        if (location.pathname.startsWith(`/networking/${id}/orders`))
            return `/networking/${id}/orders`;
        if (location.pathname.startsWith(`/networking/${id}/subscriptions`))
            return `/networking/${id}/subscriptions`;
        if (location.pathname.startsWith(`/networking/${id}/ranks`))
            return `/networking/${id}/ranks`;
        if (location.pathname.startsWith(`/networking/${id}`)) return `/networking/${id}`;
        return location.pathname;
    }, [id, location.pathname]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    backgroundColor: 'white',
                    borderBottom: '1px solid lightgrey',
                }}
            >
                <Typography variant="h6">{data?.name}</Typography>
                <Tabs
                    variant="scrollable"
                    onChange={handleChange}
                    indicatorColor="primary"
                    value={getActiveTab()}
                >
                    <Tab
                        value={`/networking/${id}`}
                        label={<Typography variant="caption">Dashboard</Typography>}
                    />
                    <Tab
                        value={`/networking/${id}/metrics`}
                        label={<Typography variant="caption">BI Metrics</Typography>}
                    />
                    <Tab
                        value={`/networking/${id}/binary-tree`}
                        label={<Typography variant="caption">Binary Tree</Typography>}
                    />
                    <Tab
                        value={`/networking/${id}/enroller-tree`}
                        label={<Typography variant="caption">Enroller Tree</Typography>}
                    />
                    <Tab
                        value={`/networking/${id}/earnings`}
                        label={<Typography variant="caption">Earnings</Typography>}
                    />
                    <Tab
                        value={`/networking/${id}/payouts`}
                        label={<Typography variant="caption">Payouts</Typography>}
                    />
                    <Tab
                        value={`/networking/${id}/sales`}
                        label={<Typography variant="caption">Product Sales</Typography>}
                    />
                    <Tab
                        value={`/networking/${id}/orders`}
                        label={<Typography variant="caption">Product Orders</Typography>}
                    />
                    <Tab
                        value={`/networking/${id}/subscriptions`}
                        label={<Typography variant="caption">Subscriptions</Typography>}
                    />
                    <Tab
                        value={`/networking/${id}/ranks`}
                        label={<Typography variant="caption">Ranking</Typography>}
                    />
                </Tabs>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#f5f5f5',
                    backgroundImage: `url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23cfd8f5' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");`,
                }}
            >
                <Switch>
                    <Route exact path={`/networking/:id`}>
                        <PartnershipDashboardScene />
                    </Route>
                    <Route exact path={`/networking/:id/metrics`}>
                        <DashboardMetricsScene />
                    </Route>
                    <Route exact path={`/networking/:id/binary-tree`}>
                        <BinaryTreeScene />
                    </Route>
                    <Route exact path={`/networking/:id/enroller-tree`}>
                        <EnrollerTreeScene />
                    </Route>
                    <Route path={`/networking/:id/earnings`}>
                        <PartnershipEarningsScene organizationId={id} />
                    </Route>
                    <Route path={`/networking/:id/payouts`}>
                        <PartnershipPayoutsScene />
                        <Route exact path={`/networking/:id/payouts/:payoutId`}>
                            <Drawer
                                anchor={'right'}
                                open={true}
                                onClose={() => {
                                    history.push(`/networking/${id}/payouts`);
                                }}
                                PaperProps={{ style: { width: isMobile ? '100%' : '60%' } }}
                            >
                                <PartnershipPayoutCommissionsListScene
                                    onClose={() => history.push(`/networking/${id}/payouts`)}
                                />
                            </Drawer>
                        </Route>
                    </Route>
                    <Route path={`/networking/:id/sales`}>
                        <ProductSalesListScene />
                    </Route>
                    <Route path={`/networking/:id/orders`}>
                        <ProductOrdersListScene />
                    </Route>
                    <Route exact path={`/networking/:id/subscriptions`}>
                        <SubscriptionScene />
                    </Route>
                    <Route path={`/networking/:id/ranks`}>
                        <PartnershipRanksScene />
                    </Route>
                </Switch>
            </Box>
        </>
    );
};
export default OrganizationPartnerDetailsOverviewScene;
