import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Paper,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Area, AreaChart, ReferenceLine, ResponsiveContainer } from 'recharts';
import { Individual } from '../../../utils/types';
import {
    loadDistanceBySpeedData,
    loadHighSpeedSprintsData,
    loadSessionData,
    loadSprintData,
} from '../api/kpi-queries';
import { fetchSignedUrl, getRawSessionData } from '../api/performance.api';
import { aggregateData } from '../community.func';
import SpeedManagementPlayerMetricsGrid from './SpeedManagementPlayerMetricsGrid';

interface SpeedManagementIndividualCardProps {
    individual: Individual;
}

const SpeedManagementIndividualCard = ({ individual }: SpeedManagementIndividualCardProps) => {
    const [loading, setLoading] = useState(false);
    const [maxSpeed, setMaxSpeed] = useState<number | null>(null);
    const { push } = useHistory();
    const [metrics, setMetrics] = useState<{
        highSpeedSprints7Days: number | null;
        sprints7Days: number | null;
        distance7Days: number | null;
        load7Days: number | null;
        accels7Days: number | null;
        decels7Days: number | null;
        highSpeedSprints30Days: number | null;
        sprints30Days: number | null;
        distance30Days: number | null;
        load30Days: number | null;
        accels30Days: number | null;
        decels30Days: number | null;
        highSpeedDistanceEighty7Days: number | null;
        highSpeedDistanceEighty30Days: number | null;
        highSpeedDistanceEightyFive7Days: number | null;
        highSpeedDistanceEightyFive30Days: number | null;
    }>();
    const [rawDataBodies, setRawDataBodies] = useState<
        Array<{
            data: Array<{ t: number; s: number }>;
            uuid: string;
            activity_date: string;
            individual_uuid: string;
        }>
    >([]);

    useEffect(() => {
        if (!individual) {
            return;
        }

        const loadData = async () => {
            setLoading(true);
            try {
                const response = await loadSprintData(individual.uuid);
                const sessionResponse = await loadSessionData(individual.uuid);
                const highSpeedSprintsResponse = await loadHighSpeedSprintsData(individual.uuid);
                const rawSessionDataResponse = await getRawSessionData(individual.uuid);
                // Create array of empty objects with metadata
                const emptyRawDataBodies = rawSessionDataResponse.data.map((session) => ({
                    data: [],
                    uuid: session.uuid,
                    activity_date: session.activity_date,
                    individual_uuid: session.individual_uuid,
                }));

                setRawDataBodies(emptyRawDataBodies);

                // Process each URL individually instead of using Promise.all
                rawSessionDataResponse.data.forEach(async (session, index) => {
                    try {
                        const result = await fetchSignedUrl(session.signed_url);

                        // Update just this specific session's data
                        setRawDataBodies((currentBodies) =>
                            currentBodies.map((body, i) =>
                                i === index ? { ...body, data: result.data } : body,
                            ),
                        );
                    } catch (error) {
                        console.error(`Error fetching data for session ${session.uuid}:`, error);
                    }
                });

                const responseTable = response.tablePivot({
                    fillMissingDates: true,
                });

                const speedAggregatedData = aggregateData(
                    responseTable,
                    'FactSprintsRanked.vMaxMaxMph',
                    'max',
                );

                const sprintsAggregatedData = aggregateData(
                    responseTable,
                    'FactSprintsRanked.count',
                    'sum',
                );

                const sessionResponseTable = sessionResponse.tablePivot({
                    fillMissingDates: true,
                });

                const distanceAggregatedData = aggregateData(
                    sessionResponseTable,
                    'FactUploads.totalDistanceYd',
                    'sum',
                );

                const loadAggregatedData = aggregateData(
                    sessionResponseTable,
                    'FactUploads.totalPlayerLoad',
                    'sum',
                );

                const accelAggregatedData = aggregateData(
                    sessionResponseTable,
                    'FactUploads.totalAccelerations',
                    'sum',
                );

                const decelAggregatedData = aggregateData(
                    sessionResponseTable,
                    'FactUploads.totalDecelerations',
                    'sum',
                );

                const highSpeedSprintsTable = highSpeedSprintsResponse.tablePivot({
                    fillMissingDates: true,
                });

                const highSpeedSprintsAggregatedData = aggregateData(
                    highSpeedSprintsTable,
                    'FactSprintsRanked.count',
                    'sum',
                );

                const distanceBySpeedResponseEighty = await loadDistanceBySpeedData(
                    individual.uuid,
                    speedAggregatedData.last180Days * 0.8,
                );

                const distanceBySpeedEightyTable = distanceBySpeedResponseEighty.tablePivot({
                    fillMissingDates: true,
                });

                const distanceBySpeedAggregatedDataEighty = aggregateData(
                    distanceBySpeedEightyTable,
                    'FactGpsDistances.totalDistance',
                    'sum',
                );

                const distanceBySpeedResponseEightyFive = await loadDistanceBySpeedData(
                    individual.uuid,
                    speedAggregatedData.last180Days * 0.85,
                );

                const distanceBySpeedEightyFiveTable = distanceBySpeedResponseEightyFive.tablePivot(
                    {
                        fillMissingDates: true,
                    },
                );

                const distanceBySpeedAggregatedDataEightyFive = aggregateData(
                    distanceBySpeedEightyFiveTable,
                    'FactGpsDistances.totalDistance',
                    'sum',
                );

                setMaxSpeed(speedAggregatedData.last180Days);

                // Set random values for metrics
                setMetrics({
                    sprints7Days: sprintsAggregatedData.last7Days,
                    distance7Days: distanceAggregatedData.last7Days,
                    highSpeedSprints7Days: highSpeedSprintsAggregatedData.last7Days,
                    load7Days: loadAggregatedData.last7Days,
                    accels7Days: accelAggregatedData.last7Days,
                    decels7Days: decelAggregatedData.last7Days,
                    sprints30Days: sprintsAggregatedData.last30Days,
                    distance30Days: distanceAggregatedData.last30Days,
                    load30Days: loadAggregatedData.last30Days,
                    accels30Days: accelAggregatedData.last30Days,
                    decels30Days: decelAggregatedData.last30Days,
                    highSpeedSprints30Days: highSpeedSprintsAggregatedData.last30Days,
                    highSpeedDistanceEighty7Days: distanceBySpeedAggregatedDataEighty.last7Days,
                    highSpeedDistanceEighty30Days: distanceBySpeedAggregatedDataEighty.last30Days,
                    highSpeedDistanceEightyFive7Days:
                        distanceBySpeedAggregatedDataEightyFive.last7Days,
                    highSpeedDistanceEightyFive30Days:
                        distanceBySpeedAggregatedDataEightyFive.last30Days,
                });
            } catch (error) {
                console.error('Error loading sprint data:', error);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [individual]);

    return (
        <Card
            sx={{
                maxHeight: 1200,
                display: 'flex',
                flexDirection: 'column',
                background: 'linear-gradient(180deg, #f2f2f2 0%, #dbdbdb 100%)',
            }}
        >
            <CardContent
                sx={{
                    flex: 1,
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    padding: 2,
                }}
            >
                <CardHeader
                    avatar={
                        <Avatar
                            variant={'rounded'}
                            src={individual.image_urls?.avatar || ''}
                            alt={individual.name}
                            sx={{ width: 80, height: 80 }}
                        />
                    }
                    title={
                        <Typography
                            variant="h6"
                            component="div"
                            align="center"
                            sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                        >
                            {individual.name}
                        </Typography>
                    }
                    sx={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        '.MuiCardHeader-content': {
                            width: '100%',
                        },
                    }}
                />
                <Paper sx={{ padding: 5 }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Max Speed Past 6 Months
                    </Typography>
                    <Typography variant="h5" component="div" align="center">
                        {loading ? <CircularProgress /> : maxSpeed ? maxSpeed.toFixed(2) : '-'} mph
                    </Typography>
                </Paper>
                {metrics && (
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ margin: 5 }}>
                            <SpeedManagementPlayerMetricsGrid
                                loading={loading}
                                title="Last 7 Days"
                                sprints={metrics.sprints7Days}
                                distance={metrics.distance7Days}
                                playerLoad={metrics.load7Days}
                                accelerations={metrics.accels7Days}
                                decelerations={metrics.decels7Days}
                                highSpeedSprints={metrics.highSpeedSprints7Days}
                                highSpeedDistanceEighty={metrics.highSpeedDistanceEighty7Days}
                                highSpeedDistanceEightyFive={
                                    metrics.highSpeedDistanceEightyFive7Days
                                }
                            />
                        </Paper>
                        <Paper sx={{ margin: 5 }}>
                            <SpeedManagementPlayerMetricsGrid
                                loading={loading}
                                title="Last 30 Days"
                                sprints={metrics.sprints30Days}
                                distance={metrics.distance30Days}
                                playerLoad={metrics.load30Days}
                                accelerations={metrics.accels30Days}
                                decelerations={metrics.decels30Days}
                                highSpeedSprints={metrics.highSpeedSprints30Days}
                                highSpeedDistanceEighty={metrics.highSpeedDistanceEighty30Days}
                                highSpeedDistanceEightyFive={
                                    metrics.highSpeedDistanceEightyFive30Days
                                }
                            />
                        </Paper>
                        <Paper sx={{ margin: 5, padding: 1 }}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                align="center"
                                sx={{ mb: 1 }}
                            >
                                Recent Speed Data
                            </Typography>
                            {loading ? (
                                <CircularProgress />
                            ) : rawDataBodies.length === 0 ? (
                                <Typography variant="body2" color="text.secondary" align="center">
                                    No speed data available
                                </Typography>
                            ) : (
                                rawDataBodies.map((data, index) => (
                                    <Box key={index} sx={{ width: '100%' }}>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                            component="div"
                                            sx={{
                                                cursor: 'pointer',
                                                '&:hover': { textDecoration: 'underline' },
                                            }}
                                            onClick={() => {
                                                push(
                                                    `/community/individuals/${data.individual_uuid}/performance/${data.uuid}`,
                                                );
                                            }}
                                        >
                                            {new Date(data.activity_date).toLocaleDateString()}
                                        </Typography>
                                        <ResponsiveContainer width="100%" height={100}>
                                            <AreaChart data={data.data}>
                                                <Area
                                                    type="monotone"
                                                    dataKey="s"
                                                    stroke="#8884d8"
                                                    fill="#8884d8"
                                                    dot={false}
                                                    strokeWidth={1}
                                                />
                                                {maxSpeed && (
                                                    <ReferenceLine
                                                        y={maxSpeed * 0.8 * 0.44704}
                                                        stroke="rgba(255,0,0,0.3)"
                                                        strokeDasharray="3 3"
                                                        label={{
                                                            value: '80% maxV',
                                                            position: 'insideRight',
                                                            fill: 'rgba(255,0,0,0.5)',
                                                            fontSize: 12,
                                                        }}
                                                    />
                                                )}
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </Box>
                                ))
                            )}
                        </Paper>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default SpeedManagementIndividualCard;
